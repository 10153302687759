import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LockNavigationService {
  private _isLocked = false;

  constructor() {
    window.addEventListener('beforeunload', (event) => {
      if (this._isLocked) {
        event.preventDefault();
      }
    });
  }

  public get isLocked() {
    return this._isLocked;
  }

  public lock() {
    this._isLocked = true;
  }

  public unlock() {
    this._isLocked = false;
  }
}
